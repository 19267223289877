import React from 'react';
import { Popup } from 'semantic-ui-react';
import './index.scss';
import { isMobile } from 'react-device-detect';
import * as KeyCode from 'keycode-js';

export default function WatchCtrlButton({
  children,
  position = 'bottom',
  classNames = '',
  onClick = null,
  onDoubleClick = null,
  // active = false,
  // colored = false,
  disabled = false,
  label = '',
  ariaTags = {},
  popupStyle = {},
  popupPosition = '10px, 15px',
  mouseEnterDelay = 0,
  id = '',
}) {
  const handleKeyDown = (e) => {
    if (e.keyCode === KeyCode.KEY_SPACE) {
      e.preventDefault();
    }
  };

  return (
    <Popup
      inverted
      wide
      basic
      style={popupStyle}
      position={`${position} center`}
      offset={position === 'bottom' ? popupPosition : undefined}
      mouseEnterDelay={mouseEnterDelay}
      openOnTriggerClick={false}
      openOnTriggerFocus
      closeOnTriggerBlur
      content={<strong>{label}</strong>}
      disabled={isMobile}
      trigger={
        <button
          id={id}
          className={`watch-ctrl-button ${classNames}`}
          // active={active.toString()}
          // colored={colored.toString()}
          disabled={disabled}
          // position={position}
          onClick={onClick}
          onDoubleClick={onDoubleClick}
          onKeyDown={handleKeyDown}
          {...ariaTags}
        >
          {children}
        </button>
      }
    />
  );
}
